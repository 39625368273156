.btn {
    &.icon {
        padding: 0.4rem 0.6rem;
        svg {
            width: 16px;
            height: 16px;
        }
        &.icon-left {
            svg {
                margin-right: 3px;
            }
        }
        &.icon-right {
            svg {
                margin-left: 3px;
            }
        }
    }
    &.btn-outline-white {
        color: #fff;
        border-color: #fff;
        &:hover {
            color: #333;
            background-color: #fff;
        }
    }

    @each $key, $value in $theme-colors-light {
        &.btn-light-#{$key} {
            background-color: $value;
            color: darken($value, 80%);
        }
    }

    @each $key, $value in $theme-colors {
        &.btn-#{$key} {
            @if $key == 'light' {
                color: $body-color;
            } @else {
                color: #fff;
            }
        }
    }
}
.btn-block {
    width: 100%;
}
.btn-group {
    &:not(.dropdown) .btn:not([class*='btn-']) {
        border: 1px solid #dfe3e7;
    }
    & > .btn {
        border-radius: 0.267rem;
    }
}
.buttons {
    .btn {
        margin: 0 10px 10px 0;
    }
}
